// Style GWATF for WD1 redesign
.ape-wrapper[data-weblabs~='ADPT_SF_GWATF_ROUNDED_CORNERS_1036948'] {
    &.ape-wrapper[data-page-type='gateway'][data-slot-name='atf'] {
        &.ape-wrapper[data-device-type='mweb'],
        &.ape-wrapper[data-device-type='mshop'] {
            .ape-placement {
                border: 1px solid #d5d9d9;
                border-radius: 8px;
                overflow: hidden;
                // Temp fix for aspect ratio resizing where padding changes cause the creative to be cut off
                max-width: 100% !important;
                // Temp fix for overriding height: 15.625vw from SCS (whitespace)
                height: inherit !important;

                // Hide border that appears on the top of a creative
                .border-enforcement {
                    display: none !important;
                }

                // @NOTE: SIM ticket tracking link for cleanup: https://t.corp.amazon.com/V1563500657
                // Scale up creative slightly to hide the border that is baked into the image
                img.ad-background-image {
                    transform: scaleX(1.01) scaleY(1.07) translate(-49.5%) !important;
                }
            }
        }
    }
}
