// Special styling need for mobile ads on the Search page (btf & msf).
// Moves sponsored label to the top-left and formats border.
// Doc: https://quip-amazon.com/7cjwAcRAR5lN/Loom-Mobile-Footer-Slot-Migration-to-Standard-SafeFrame
.ape-wrapper[data-device-type='mweb'],
.ape-wrapper[data-device-type='mshop'] {
    &.ape-wrapper[data-page-type='ABsearch'],
    &.ape-wrapper[data-page-type='search'] {
        display: grid;
        border: 1px solid #ccc;

        .ape-feedback {
            order: -1;
            width: 100%;
            height: 24px !important;
            text-align: left !important;
            margin-top: 0px !important;
            padding-left: 8px !important;
        }
    }
}

/**
 * For desktop, we show skyscraper ads on the left panel on the search page. We want to hide the adaptive skyscrapers
 * (e.g. sky-2) initially while the adaptive logic does its thing. Adaptive code will then show sky-2+ when it's ready.
 */
.ape-placement[data-slot-name='auto-left-advertising-2'],
.ape-placement[data-slot-name='fresh-ad-left-2'],
.ape-placement[data-slot-name='wfm-ad-left-2'] {
    display: none;

    &.show {
        display: block;
    }
}
